import { useState } from "react";
import { Button, Card, Flex, Image, Title, Modal, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { assets } from "../../constants";
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "../../constants/legalDocs";

function PrivacyPolicy() {
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Flex
      h={"100vh"}
      gap={40}
      w={"100vw"}
      align={"center"}
      direction={"column"}
      bg={"#f8f3f0"}>
      <Flex mt={40}>
        <Image w={133} h={128} src={assets.images.logo} />
      </Flex>
      <Card
        padding={"lg"}
        className="custom-scrollbar responsive-card"
        style={{ overflowY: "auto" }}
        radius={"md"}
        h={"75vh"}
        display={"flex"}
        p={15}>
        <Title mb={"sm"}>Legal Documents</Title>
        <Flex direction={isMobile ? "column" : "row"} gap={isMobile ? 10 : 40}>
          <Button
            variant="transparent"
            td={"underline"}
            size="lg"
            radius={"xl"}
            onClick={() => setPrivacyModalOpen(true)}>
            Privacy Policy
          </Button>
          <Button
            variant="transparent"
            td={"underline"}
            size="lg"
            radius={"xl"}
            onClick={() => setTermsModalOpen(true)}>
            Terms of Service
          </Button>
        </Flex>
      </Card>

      <Modal
        opened={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        size="auto"
        centered>
        <Text dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY }} />
      </Modal>

      <Modal
        opened={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        size="auto"
        centered>
        <Text dangerouslySetInnerHTML={{ __html: TERMS_OF_SERVICE }} />
      </Modal>
    </Flex>
  );
}

export default PrivacyPolicy;
