import { useEffect, useState } from "react";
import { getAllDocs } from "../repositories/firebase";
import { COLLECTIONS, User } from "../constants/types";
import { Order } from "../constants/collections";
import { convertTimestamp } from "../modules";

export interface UsePatients {
  patients: User[];
  orders: Order[];
  loading: boolean;
}

export const usePatients = (): UsePatients => {
  const [patients, setPatients] = useState<User[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getPatients = async () => {
    try {
      const _patients = await getAllDocs<User>(COLLECTIONS.USERS);
      const _orders = await getAllDocs<Order>(COLLECTIONS.ORDERS);
      setPatients(
        _patients.map((d) => ({
          ...d,
          dateOfBirth: convertTimestamp(d.dateOfBirth as any),
        }))
      );
      setOrders(
        _orders.map((o) => ({
          ...o,
          metadata: o?.metadata || {},
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPatients();
      setLoading(false);
    })();
  }, []);

  return {
    patients,
    loading,
    orders,
  };
};
