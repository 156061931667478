import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as signOutFromFirebase,
} from "@firebase/auth";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  where,
  query,
  getDocs,
  deleteDoc,
  DocumentData,
  writeBatch,
  serverTimestamp,
} from "@firebase/firestore";
import { ref, getDownloadURL, getStorage, uploadBytes } from "firebase/storage";
import { auth, db } from "../firebase";
import { COLLECTIONS, FirebaseResponse } from "../constants/types";
import { OrderStatus } from "../constants/enums";
import { Order } from "../constants/collections";

export const signUp = async (
  email: string,
  password: string
): Promise<FirebaseResponse> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      email: user.email,
    });
    return { success: true, message: "Signed up successfully!" };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
export const signIn = async (
  email: string,
  password: string
): Promise<FirebaseResponse> => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return { success: true, message: "Signed in successfully!", data: user };
  } catch (error: any) {
    return { success: false, message: error.message, code: error?.code };
  }
};

export const signOut = async (): Promise<FirebaseResponse> => {
  try {
    await signOutFromFirebase(auth);
    return { success: true, message: "Logged out successfully!" };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

/**
 * Upsert a document in a collection
 * @param collectionName  collection name
 * @param data  data to be inserted
 * @param documentId  document id (optional)
 * @returns FirebaseResponse
 */

export const upsertDoc = async <T>(
  collectionName: COLLECTIONS,
  data: T,
  documentId?: string,
  ext?: { createdAt?: boolean; updatedAt?: boolean }
): Promise<FirebaseResponse> => {
  let { createdAt = false, updatedAt = false } = ext || {};
  try {
    let response: any = undefined;
    let dataWithTimestamp: any = { ...data };

    if (createdAt && !documentId) {
      dataWithTimestamp.createdAt = serverTimestamp();
    }
    if (updatedAt) {
      dataWithTimestamp.updatedAt = serverTimestamp();
    }

    if (documentId) {
      const documentRef = doc(db, collectionName, documentId);
      await setDoc(documentRef, dataWithTimestamp, { merge: true });
    } else {
      const collectionRef = collection(db, collectionName);
      response = await addDoc(collectionRef, dataWithTimestamp);
    }

    return {
      success: true,
      message: "Data updated successfully!",
      data: response,
    };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const addSubcollectionDoc = async <T>(
  collectionName: COLLECTIONS,
  docId: string = "",
  subcollectionName: string,
  data: T,
  {
    createdAt = false,
    updatedAt = false,
  }: { createdAt?: boolean; updatedAt?: boolean }
): Promise<FirebaseResponse> => {
  try {
    if (!docId) return { success: false, message: "Missing parent doc id." };
    let response: any = undefined;
    let dataWithTimestamp: any = { ...data };

    if (createdAt) {
      dataWithTimestamp.createdAt = serverTimestamp();
    }
    if (updatedAt) {
      dataWithTimestamp.updatedAt = serverTimestamp();
    }

    const subcollectionRef = collection(
      db,
      collectionName,
      docId,
      subcollectionName
    );
    response = await addDoc(subcollectionRef, dataWithTimestamp);

    return {
      success: true,
      message: "Subcollection document added successfully!",
      data: response,
    };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const upsertDocWhere = async <T>(
  collectionName: COLLECTIONS,
  data: T,
  queryKey: keyof T,
  queryValue: any
): Promise<FirebaseResponse> => {
  try {
    const q = query(
      collection(db, collectionName),
      where(queryKey as string, "==", queryValue)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty && querySnapshot.docs.length) {
      let documentId = querySnapshot.docs[0].id;
      const documentRef = doc(db, collectionName, documentId);
      await setDoc(documentRef, data || {}, { merge: true });
      return { success: true, message: "Data updated successfully!" };
    } else {
      return { success: false, message: "Could not find record" };
    }
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

/**
 *  Get a document
 * @param collectionName  collection name
 * @param queryKey  query key
 * @param queryValue  query value
 * @returns  document data
 */
export async function getDocByQuery<T>(
  collectionName: COLLECTIONS,
  queryKey: keyof T,
  queryValue: any
): Promise<T | undefined> {
  const q = query(
    collection(db, collectionName),
    where(queryKey as string, "==", queryValue)
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return undefined;
  } else {
    return {
      id: querySnapshot.docs[0].id,
      ...querySnapshot.docs[0].data(),
    } as T;
  }
}

/**
 *  Get a document
 * @param collectionName  collection name
 * @param id  id of doc to delete
 * @returns  document data
 */
export async function deleteDocFromCol(
  collectionName: COLLECTIONS,
  id: string
): Promise<boolean> {
  try {
    // delete doc
    await deleteDoc(doc(db, collectionName, id));
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Delete all documents from a specified collection.
 * @param collectionName The name of the collection to delete documents from.
 * @returns Promise<void>
 */
export async function deleteAllDocsFromCollection(
  collectionName: string
): Promise<void> {
  const collectionRef = collection(db, collectionName);
  const batchSize = 500; // Firestore batch write limit

  const deleteBatch = async () => {
    // Query the first batch of documents
    const q = query(collectionRef);
    const snapshot = await getDocs(q);
    const docs = snapshot.docs;

    // If there are no documents left, we are done
    if (docs.length === 0) {
      return;
    }

    // Start a new batch
    const batch = writeBatch(db);

    docs.forEach((doc) => batch.delete(doc.ref));

    // Commit the batch
    await batch.commit();

    // If we deleted the maximum amount of documents, there might be more to delete
    if (docs.length >= batchSize) {
      await deleteBatch(); // Recursively delete the next batch
    }
  };

  await deleteBatch();
}

/**
 *  Get a document
 * @param collectionName  collection name
 * @param queryKey  query key
 * @param queryValue  query value
 * @returns  document data
 */
export async function getDocsByQuery<T>(
  collectionName: COLLECTIONS,
  queryKey: keyof T,
  queryValue: any
): Promise<T[]> {
  const q = query(
    collection(db, collectionName),
    where(queryKey as string, "==", queryValue)
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as T[];
  }
}

/**
 * Get a document
 * @param collectionName Collection name
 * @param nestedCollectionName Optional nested collection name
 * @param queryKey Query key
 * @param queryValue Query value
 * @returns Document data
 */
export async function getAllDocs<T>(
  collectionName: COLLECTIONS,
  nestedCollectionName?: string
): Promise<T[]> {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    const result: T[] = [];

    for (const doc of querySnapshot.docs) {
      const docData: DocumentData = {
        id: doc.id,
        ...doc.data(),
      };

      if (nestedCollectionName) {
        const nestedCollectionRef = collection(
          db,
          collectionName,
          doc.id,
          nestedCollectionName
        );

        const nestedQuerySnapshot = await getDocs(nestedCollectionRef);

        docData[nestedCollectionName] = nestedQuerySnapshot.docs.map(
          (nestedDoc) => ({
            id: nestedDoc.id,
            ...nestedDoc.data(),
          })
        );
      }

      result.push(docData as T);
    }

    return result;
  }
}

export async function uploadFileToFirebaseStorage(
  file: File,
  fileName: string,
  folder: string = "uploads"
): Promise<string> {
  try {
    // Get the Firebase Storage service
    const storage = getStorage();

    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storage, `${folder}/${fileName}`);

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file);

    // Get URL for the file to show in background
    const url = await getDownloadURL(fileRef);

    return url;
  } catch (e) {
    return "";
  }
}

/**
 * Get a document with support for nested collections at any level
 * @param collectionName Collection name
 * @param nestedCollectionNames Array of nested collection names
 * @returns Document data
 */
export async function getAllDocsNestedRecursively<T>(
  collectionName: COLLECTIONS,
  nestedCollectionNames?: string[]
): Promise<T[]> {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    const result: T[] = [];

    for (const doc of querySnapshot.docs) {
      const docData: DocumentData = {
        id: doc.id,
        ...doc.data(),
      };

      if (nestedCollectionNames && nestedCollectionNames.length > 0) {
        await processNestedCollections(docData, doc.id, nestedCollectionNames);
      }

      result.push(docData as T);
    }

    return result;
  }
}

/**
 * Recursively process nested collections
 * @param data Current data object
 * @param parentId Parent document ID
 * @param nestedCollectionNames Array of nested collection names
 */
async function processNestedCollections(
  data: DocumentData,
  parentId: string,
  nestedCollectionNames: string[]
): Promise<void> {
  for (const nestedCollectionName of nestedCollectionNames) {
    const nestedCollectionRef = collection(
      db,
      data.id,
      parentId,
      nestedCollectionName
    );

    const nestedQuerySnapshot = await getDocs(nestedCollectionRef);

    data[nestedCollectionName] = await Promise.all(
      nestedQuerySnapshot.docs.map(async (nestedDoc) => {
        const nestedDocData: DocumentData = {
          id: nestedDoc.id,
          ...nestedDoc.data(),
        };

        // Recursively process deeper nested collections
        await processNestedCollections(
          nestedDocData,
          nestedDoc.id,
          nestedCollectionNames.slice(1)
        );

        return nestedDocData;
      })
    );
  }
}

/**
 * Get a document
 * @param collectionName Collection name
 * @param firstNestedCollectionName Optional first nested collection name
 * @param secondNestedCollectionName Optional second nested collection name
 * @param queryKey Query key
 * @param queryValue Query value
 * @returns Document data
 */
export async function getAllDocsNested<T>(
  collectionName: COLLECTIONS,
  firstNestedCollectionName?: string,
  secondNestedCollectionName?: string
): Promise<T[]> {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    const result: T[] = [];

    for (const doc of querySnapshot.docs) {
      const docData: DocumentData = {
        id: doc.id,
        ...doc.data(),
      };

      if (firstNestedCollectionName) {
        const firstNestedCollectionRef = collection(
          db,
          collectionName,
          doc.id,
          firstNestedCollectionName
        );

        const firstNestedQuerySnapshot = await getDocs(
          firstNestedCollectionRef
        );

        docData[firstNestedCollectionName] = firstNestedQuerySnapshot.docs.map(
          (firstNestedDoc) => ({
            id: firstNestedDoc.id,
            ...firstNestedDoc.data(),
          })
        );

        if (secondNestedCollectionName) {
          // Add support for the second level of nesting
          for (const nestedDoc of firstNestedQuerySnapshot.docs) {
            const secondNestedCollectionRef = collection(
              db,
              collectionName,
              doc.id,
              firstNestedCollectionName,
              nestedDoc.id,
              secondNestedCollectionName
            );

            const secondNestedQuerySnapshot = await getDocs(
              secondNestedCollectionRef
            );

            const nestedData = docData[firstNestedCollectionName].find(
              (item: any) => item.id === nestedDoc.id
            );

            if (nestedData) {
              nestedData[secondNestedCollectionName] =
                secondNestedQuerySnapshot.docs.map((secondNestedDoc) => ({
                  id: secondNestedDoc.id,
                  ...secondNestedDoc.data(),
                }));
            }
          }
        }
      }

      result.push(docData as T);
    }

    return result;
  }
}

export const onOrderStatusChange = async (
  id: string,
  status: OrderStatus,
  data: Order = {} as Order
) => {
  try {
    await upsertDoc<any>(
      COLLECTIONS.ORDERS,
      {
        ...data,
        metadata: { dsdsd: "ddddddd" },
        currentStatus: {
          status: status,
          date: new Date(),
        },
      },
      id
    );
  } catch (error) {}
};
