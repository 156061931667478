import {
  Button,
  Card,
  Flex,
  Input,
  Space,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import styled from "styled-components";
import MedicationCard from "../components/MedicationCard";
import { useAppData } from "../../../contexts/AppContext";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { BatchRuleActionType } from "../../../constants/enums";
import { Step } from "../../../constants/types";
import { BILL_SUMMARY } from "../../../constants";

function OrderSummary() {
  const { data, setData, setDisableContinue } = useAppData();
  const form = useForm({
    initialValues: {
      ...data.paymentInfo,
      expiryDate: "",
    },
    validate: {
      creditCardNumber: (value) =>
        value === "" ? "Credit Card Number required" : null,
      expiryDate: (value) => (value === "" ? "Expiry Date required" : null),
      securityCode: (value) => (value === "" ? "Security Code required" : null),
      firstName: (value) => (value === "" ? "First Name required" : null),
      lastName: (value) => (value === "" ? "Last Name required" : null),
    },
  });

  useEffect(() => {
    const [expiryMonth, expiryYear] = form?.values?.expiryDate?.split("/");
    setData((data) => ({
      ...data,
      paymentInfo: {
        ...form.values,
        expiryMonth,
        expiryYear,
      },
    }));

    setDisableContinue({
      [Step.OrderSummary]: !form.isValid(),
    });
    if (form.isTouched()) {
      form.validate();
    }
  }, [form.values]);

  return (
    <Flex className="responsive-card my-order-summary-holder" direction={"column"} miw={"60vw"}>
      <StyledTitle mb={15}>Order Summary</StyledTitle>
      <InnerContainer>
        <Flex direction={"column"} gap={15} flex={2} w={"100%"}>
          <Card>
            <Text style={{ fontSize: 18 }} mb={10}>
              Summary
            </Text>
            <MedicationCard editable={false} />
            {data.rushOrder ? (
              <Flex justify={"space-between"}>
                <Text>Rush order</Text>
                <Text style={{ textAlign: "left" }}>$25</Text>
              </Flex>
            ) : null}

            <Flex justify={"space-between"}>
              <Text>Medicines</Text>
              <Text style={{ textAlign: "left" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(
                  data?.medications
                    ?.filter(
                      (med) => !med.metadata![BatchRuleActionType.NoPayment]
                    )
                    ?.reduce(
                      (acc, med) =>
                        acc + parseFloat(med.price.replace(/^\D+/g, "")),
                      0
                    ) || 0
                )}
              </Text>
            </Flex>

            <Space h={"xs"} />
            {BILL_SUMMARY.map((summary) => (
              <Flex justify={"space-between"} key={summary.name}>
                <Text>{summary.name}</Text>
                <Text style={{ textAlign: "left" }}>
                  {summary.price ? `$${summary.price}` : "TBD"}
                </Text>
              </Flex>
            ))}

            <Space h={"xs"} />
            {data.shippingOption ? (
              <Flex justify={"space-between"}>
                <Text>Delivery</Text>
                <Text style={{ textAlign: "left" }}>
                  $
                  {data.shippingOption &&
                  !isNaN(parseFloat(data.shippingOption))
                    ? parseFloat(data.shippingOption)
                    : 0}
                </Text>
              </Flex>
            ) : null}
            <Space h={"xs"} />
            {/* <Flex justify={"space-between"}>
              <Text>Subtotal</Text>
              <Text>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(data.totalAmount || 0)}
              </Text>
            </Flex> */}

            <Space h={"xs"} />
            <Flex gap={8} style={{ alignSelf: "flex-end" }}>
              <Text fw={500}>Total</Text>
              <Text fw={500}>${data.totalAmount?.toFixed(2)}</Text>
            </Flex>
          </Card>
        </Flex>
        <Flex direction={"column"} flex={4} gap={15} className="my-os-holder-inner">
          {/* {order?.metadata![BatchRuleActionType.NoPayment] ? null : ( */}
          <Card mih={"100%"} w={"100%"}>
            <Text fw={500}>Credit Card</Text>
            <Space h="xs" />
            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "lg", sm: "lg" }}>
              <TextInput
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="First Name"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                maw={"800px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Last Name"
                {...form.getInputProps("lastName")}
              />
            </Flex>
            <Space h="md" />

            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "lg", sm: "lg" }}>
              <TextInput
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Credit Card Number"
                {...form.getInputProps("creditCardNumber")}
              />
              <TextInput
                maw={"800px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Security Code"
                {...form.getInputProps("securityCode")}
              />
            </Flex>
            <Space h="md" />
            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "lg", sm: "lg" }}>
              <ExpirationDateInput
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Expiration Date (MM/YY)"
                {...form.getInputProps("expiryDate")}
              />
              <Input
                maw={"800px"}
                radius="md"
                size="md"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "2px",
                  },
                }}
                placeholder="Zip Code"
                {...form.getInputProps("zipCode")}
              />
            </Flex>

            <Space h="xl" />
          </Card>
        </Flex>
      </InnerContainer>
    </Flex>
  );
}

export default OrderSummary;

const InnerContainer = styled.div`
  gap: 15px;
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;
const StyledTitle = styled(Title)`
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

function ExpirationDateInput({ ...props }) {
  const handleChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 2) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    }
    props.onChange(value);
  };

  return (
    <TextInput
      {...props}
      onChange={handleChange}
      maxLength={5} // MM/YY format
    />
  );
}
