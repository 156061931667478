/** @format */

import {
  Card,
  Checkbox,
  Flex,
  Input,
  Space,
  Text,
  ScrollArea,
} from "@mantine/core";
import styled from "styled-components";
import { useAppData } from "../../../contexts/AppContext";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { Step } from "../../../constants/types";

function Signature() {
  const { data, setData, setDisableContinue, order } = useAppData();
  const form = useForm({
    initialValues: {
      signature: data.signature,
      acknowledgment: false,
    },
  });

  useEffect(() => {
    setData((data) => ({
      ...data,
      signature: form.values.signature,
    }));
  }, [form.values, order]);

  useEffect(() => {
    let isAnyMedAutoRefill =
      data?.medications.some((med) => med.autoRefill) || false;

    let shouldCheckAck = isAnyMedAutoRefill;

    setDisableContinue({
      [Step.Signature]:
        !form.values.signature ||
        (shouldCheckAck && !form.values.acknowledgment),
    });
  }, [form.values, data]);

  let shouldShowAck =
    order?.medications.some((med) => med.isAutoRefillVisible) || false;

  return (
    <Card
      radius={"md"}
      className="responsive-card"
      mih={"60vh"}
      display={"flex"}
      p={25}
    >
      <ScrollArea>
        <Flex
          direction={"column"}
          justify={"space-between"}
          h={"100%"}
          gap={20}
        >
          <Flex direction={"column"}>
            <Text fw={700}>Signature</Text>
            <Space h="md" />
            <Text>
              I confirm that i answered all questions truthfully and to the best of my ability. I accept that i am
              forgoing pharmacist counseling, but i can call The Art of Medicine during operating hours to ask questions
              about the medication i am receiving. Always follow your provider's recommendations before using this
              medicine. I confirm and acknowledge that I agree to the Terms and Privacy Policy.
            </Text>
            <Space h="md" />

            <Input
              size="xl"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "3px",
                },
              }}
              placeholder="Type to create signature"
              {...form.getInputProps("signature")}
            />
            <Space h="xl" />

            {shouldShowAck && (
              <Checkbox
                readOnly
                label={
                  <Text>
                    I acknowledge that auto-refills are processed automatically
                    without notification to you. We will begin processing the
                    order 3 business days in advance and use the same
                    delivery/pickup method as the previous fill. It is your sole
                    responsibility to notify us of a cancellation or delivery
                    change at least 3 business days in advance of your order due
                    date. We will not refund any orders that have been made.
                  </Text>
                }
                {...form.getInputProps("acknowledgment")}
              />
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Card>
  );
}

export default Signature;

const Container = styled.div`
  overflow-y: scroll;
`;
