/** @format */

export interface IAppFlow {}

export enum Step {
  AddPatient = "AddPatient",
  Prescription = "Prescription",
  Questioner = "Questioner",
  AddressDetails = "AddressDetails",
  BillingAddress = "BillingAddress",
  OrderSummary = "OrderSummary",
  Signature = "Signature",
  EndGreetings = "EndGreetings",
  Completed = "Completed",
}

export const STEPS_ORDER: Step[] = [
  Step.AddPatient,
  Step.Prescription,
  Step.Questioner,
  Step.AddressDetails,
  Step.BillingAddress,
  Step.OrderSummary,
  Step.Signature,
  Step.EndGreetings,
];

export enum Member {
  Pet = "Pet",
  Human = "Human",
}

export enum Specie {
  Pet = "Pet",
  Human = "Human",
}

export interface IPet {
  id: string | number;
  name: string;
  dob?: Date;
  medications: IMedication[];
}

export interface IHuman {
  id: string | number;
  firstName: string;
  lastName: string;
  name: string;
  dob: Date | undefined;
  medications: IMedication[];
}

export interface IMedication {
  id: string;
  name: string;
  quantity: string;
  type: "tablet" | "cream";
  price: number;
  autoRefill: boolean;
  dosage: string;
}
export interface IUser {
  id: string;
  firstName: string;
  lastName?: string;
  medications: IMedication[];
}

export interface IData {
  birthday: Date | undefined;
  type: Member;
  phone: string;
  notes: string;
  internalNotes: string;
  email: string;
  patients: IPet[] | IHuman[];
  questions: any[];
  addresses: {
    billingAddress: any;
    shippingAddress: any;
    bothSame: boolean;
  };
  paymentInfo: any;
  signature: string;
  pharmacyCall: boolean;
  onHold: boolean;
  acknowledgment: boolean;
  name: string;
  medications: {
    itemName: string; // The name of the item or medication.
    price: string; // The price of the item.
    quantity: number; // The quantity of the item ordered.
    dosageForm: string;
    ogQty?: any;
    ogPrice?: any;
    alt_price?: string;
    alt_qty?: string;
    isAutoRefillVisible?: boolean;
    autoRefill?: boolean;
    metadata?: any;
    flavour?: string;
  }[]; // Array of Objects - A list of items within the order.

  flavour?: string;
  shippingOption?: string;

  termsAgree?: boolean;
  rushOrder?: boolean;
  totalAmount?: number;
  initialAmount?: number;
  pregBF?: boolean;
  signatureWaived?: boolean;
  originalAmount?: number;
}

export interface User {
  id?: string;
  firstName: string; //
  lastName: string; //
  dateOfBirth: Date; // The user's date of birth, used for identity confirmation (Not required for pets).
  phone: string; //
  email?: string; //
  species: Specie; // (enums: human, pet)
  doctor: string; //
  termsAccepted?: boolean; // Indicates whether the user has agreed to the terms of service and privacy policy.
  address?: {
    street: string; //
    street2: string; //  (optional)
    city: string;
    state: string;
    postalCode: string;
  };
  sameAddress?: boolean;
  billingAddress?: // (optional, will be supplied if sameAddress is false)
  {
    street: string;
    street2: string; //  (optional)
    city: string;
    state: string;
    postalCode: string;
  };
  connectedAccounts?: Object[]; // Array of Objects
  user?: string; // Ref to User
  addedAt?: Date; // Timestamp
  isConnected?: boolean; // This will identify if an user is already connected to by some other user
  allergies?: string[]; // Array of Strings - A list of any drug allergies the user has.
  medicalConditions?: string[]; // Array of Strings - A list of any existing medical conditions the user has.
  internalNotes?: string; //  (optional)
  notes?: string; //  (optional)
  signature?: string; //  - An image of user’s hand signature
  "user-logs"?: {
    title: string;
    description: string;
    createdAt?: Date;
    createdBy?: string; // Ref to Admin / Ref to User
  }[];
}

export enum COLLECTIONS {
  USERS = "users",
  ORDERS = "orders",
  ADMINS = "admins",
  PATIENTS = "patients",
  BATCH_PREVIEW = "batch-preview",
}

export type FirebaseResponse = {
  success: boolean;
  message: string;
  data?: any;
  code?: string;
};
