import { Button, Flex, Image, Space } from "@mantine/core";
import React, { Fragment, PropsWithChildren } from "react";
import { assets } from "../constants";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { Step } from "../constants/types";
import { useAppData } from "../contexts/AppContext";

interface PageWrapperProps {
  onContinue?: () => void;
  onPrevious?: () => void;
  submitting?: boolean;
  disableContinue?: boolean;
  styles?: {
    buttonsContainer?: React.CSSProperties;
  };
}

const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = (props) => {
  const {
    onContinue,
    onPrevious,
    styles = {},
    submitting,
    disableContinue,
  } = props;
  const { appFlow } = useAppData();

  let { buttonsContainer = {} } = styles;

  const buttonStyle = {
    width: "250px",
  };

  const responsiveButtonStyle = {
    width: "240px",
    fontSize: "15px",
  };


    return (
    <Flex
      w={"100vw"}
      direction={"column"}
      align={"center"}
      className={'top-pg-wrapper '+appFlow.currentStep?.toLowerCase()+'-page-wrapper' }
      justify={"space-evenly"}>
      <Flex>
        <Image w={133} h={128} src={assets.images.logo} />
      </Flex>
      <Flex
        className="no-scrollbar page-mn-wrapper"
        direction={"column"}
        align={"center"}>
        {props.children}
      </Flex>

      <Flex
        className="footer-buttons"
        style={{
          ...buttonsContainer,
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
        w={"90%"}
        gap={10}>
        {onPrevious ? (
          <Button
            onClick={onPrevious}
            size="lg"
            radius={"xl"}
            style={{ width: "200px" }} // Adjust the minWidth as needed
            variant="subtle">
            <IconArrowNarrowLeft />
            <Space w={8} />
            <span>Previous</span>
          </Button>
        ) : (
          <div style={{ flexGrow: 1 }} />
        )}

        {onContinue && (
          <Fragment>
            {appFlow.currentStep !== Step.EndGreetings && (
              <Button
                disabled={disableContinue}
                loading={submitting}
                onClick={onContinue}
                size="lg"
                radius={"xl"}
                style={{
                  ...buttonStyle,
                  ...(window.innerWidth <= 600 ? responsiveButtonStyle : {}),
                }}
                className="continue-button">
                {appFlow.currentStep === Step.OrderSummary
                  ? "Purchase & Continue"
                  : "Continue"}
              </Button>
            )}
          </Fragment>
        )}
      </Flex>
    </Flex>
  );
};

export default PageWrapper;
