import "./App.css";
import styled from "styled-components";
import { Home } from "./pages";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container>
            <Home />
          </Container>
        }
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;

const Container = styled.div`
  background-color: #f8f3f0;
  //height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;
