import { FC, useState } from "react";
import { Button, Card, Flex, LoadingOverlay, Text } from "@mantine/core";
import { useAppData } from "../../contexts/AppContext";
import { useSearchParams } from "react-router-dom";
import { BatchRuleActionType, Specie } from "../../constants/enums";
import { Member } from "../../constants/types";
import ConfirmModalFrame from "../../components/ConfirmModalFrame";
import { modals } from "@mantine/modals";
import { Datepicker, setOptions } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { colors } from "../../constants";
setOptions({
  theme: "ios",
  themeVariant: "light",
});

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

const Auth: FC = () => {
  const {
    data,
    setData,
    patientsData: { patients, loading, orders },
    setOrder,
    setAuthenticated,
  } = useAppData();
  const [searchParams] = useSearchParams();
  const [verifying, setVerifying] = useState(false);
  const [phone, setPhone] = useState("");

  const onVerify = async () => {
    debugger;
    //if (!searchParams.get("uid") || !searchParams.get("rxNumber")) {
    if (
        !(searchParams.get("oid") && searchParams.get("uid")) && // Either both oid and uid must be present
        !searchParams.get("rxNumber") // rxNumber must always be present
    ){
      return showAlert("You're using an invalid sign-in link!");
    }

    setVerifying(true);
    await sleep(500);
    setVerifying(false);
    // We are using the Order id and then getting the User Id from the Order
    let orderUrl = orders?.find(
      (o) => o.rxNumber === searchParams.get("rxNumber") && (o.id === searchParams.get("oid") || o.userId == searchParams.get("uid"))
    )
    let user = patients?.find((p) => p.id === orderUrl?.userId);

    if (!user) {
      return showAlert("User not found!");
    }

    let type =
      user.species?.toLowerCase() === Specie.human ? Member.Human : Member.Pet;

    if (type === Member.Human) {
      if (!data?.birthday) {
        return showAlert("Invalid birthday!");
      }
    } else {
      if (!phone) {
        return showAlert("Invalid phone number!");
      }
    }

    let order = orderUrl;
    // let order = orders?.find(
    //   (o) => o.rxNumber === searchParams.get("rxNumber")
    // );
    if (!order) {
      return showAlert("Cannot find order! ");
    } else {
      if (
        order?.currentStatus?.status === "ordered" ||
        order?.currentStatus?.status === "archived"
      ) {
        return showAlert(
          "This link has been expired! Please call the pharmacy to clarify: (215) 238-9055."
        );
      }
    }

    setOrder({
      ...order,
      isAutoRefillVisible:
        order?.metadata && order?.metadata![BatchRuleActionType.AutoRefill],
    });

    if (type === Member.Human) {
      if (
        !moment(user?.dateOfBirth)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .isSame(
            moment(data?.birthday)
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
          )
      ) {
        return showAlert(
          `Incorrect DOB. Please call the pharmacy to clarify: (215) 238-9055.`
        );
      }
    } else {
      let uphone = user?.phone?.replace("+","");
      let ent_phone = phone?.replace("+","");
      if (uphone !== ent_phone) {
        return showAlert(
          `Incorrect Phone number. Please call the pharmacy to clarify: (215) 238-9055.`
        );
      }
    }

    setData({
      ...data,
      name: user.firstName + " " + user.lastName,
      medications: order.medications,
      type: type,
    });

    setAuthenticated(true);
  };

  const showAlert = (message: string) => {
    modals.open({
      children: (
        <ConfirmModalFrame
          title={message}
          confirmText="Okay"
          onConfirm={() => {
            modals.close("alert");
          }}
        />
      ),
      onClose: () => {},
      modalId: "alert",
      closeButtonProps: {
        display: "none",
      },
      centered: true,
      styles: {
        header: {
          display: "none",
        },
      },
    });
  };

  return (
    <Card
      className="custom-scrollbar responsive-card"
      style={{ overflowY: "auto" }}
      radius={"md"}
      mih={"55vh"}
      miw={"55vw"}
      display={"flex"}
      p={15}>
      <LoadingOverlay visible={loading} />
      <Flex
        direction={"column"}
        h={"100%"}
        align={"center"}
        justify={"center"}
        gap={20}>
        <Flex justify={"center"} gap={"lg"}>
          <div>
            <Text>
              {" "}
              Please enter your{" "}
              {data.type === Member.Human ? "birthday" : "phone number"} to
              confirm your identity.
            </Text>

            {data.type === Member.Human ? (
              <div style={{ margin: 20, textAlign: "center" }}>
                <Datepicker
                  controls={["date"]}
                  display="anchored"
                  value={data.birthday}
                  onChange={(event: any) => {
                    setData({
                      ...data,
                      birthday: event.value ? new Date(event.value) : undefined,
                    });
                  }}
                  placeholder="Please select..."
                  touchUi={true}
                />
              </div>
            ) : (
              <div style={{ margin: 20, textAlign: "center" }}>
                <PhoneInput
                  inputStyle={{
                    border: `3px solid ${colors.primaryColor}`,
                    borderRadius: 8,
                    height: 59,
                    width: "100%",
                  }}
                  containerStyle={{}}
                  specialLabel=""
                  preferredCountries={["us"]}
                  country={"us"}
                  value={phone}
                  onChange={(phone) => {
                    setPhone(phone);
                  }}
                />
              </div>
            )}
          </div>
        </Flex>

        <Button loading={verifying} onClick={onVerify} radius={"md"} size="lg">
          Verify
        </Button>
      </Flex>
    </Card>
  );
};

export default Auth;
