import { Card, Checkbox, Chip, Flex, Input, Space, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useAppData } from "../../../contexts/AppContext";
import { Step } from "../../../constants/types";
import { BatchRuleActionType } from "../../../constants/enums";

function Questioner() {
  const { setData, data, setDisableContinue, order } = useAppData();
  const [questions, setQuestions] = useState(data.questions);

  const onChangeQuestion = (key: string, value: any, question: string) => {
    setQuestions(
      questions.map((q) => {
        if (q.question === question) {
          return {
            ...q,
            [key]: value,
          };
        } else return q;
      })
    );
  };

  useEffect(() => {
    setData((data) => ({
      ...data,
      questions: questions,
    }));
    let emptyQuestions = questions.some((q) => !q.answer && !q.none);
    setDisableContinue({
      [Step.Questioner]: emptyQuestions ? true : false,
    });
  }, [questions]);

  return (
    <Card
      w={"40vw"}
      className="responsive-card"
      style={{ overflowY: "scroll" }}>
      <Flex direction={"column"} h={"100%"} gap={20} mb={6}>
        <Text fw={500}>
          If you are an existing patient in our system, click "none" for all 3
          unless you want your profile updated.
        </Text>
        {questions.map((Q) => {
          return (
            <Flex direction={"column"} key={Q.question}>
              <Flex gap={5}>
                <Text fw={700}>{Q.question}</Text>
                <div className="is-danger">*</div>
              </Flex>
              <Space h="xs" />

              <Checkbox
                checked={Q.none}
                onChange={(e) =>
                  onChangeQuestion("none", e.target.checked, Q.question)
                }
                label={<Text>If none, click here</Text>}
              />

              <Input
                disabled={Q.none}
                radius={"md"}
                mt={5}
                value={Q.answer}
                onChange={(e) =>
                  onChangeQuestion("answer", e.target.value, Q.question)
                }
                size="lg"
                styles={{
                  input: {
                    borderColor: "#6E23CF",
                    borderWidth: "3px",
                  },
                }}
                placeholder={Q.placeHolder}
              />
            </Flex>
          );
        })}
        {order?.medications?.some(
          (med) =>
            med?.metadata![BatchRuleActionType.CheckForPregnantBreastfeeding]
        ) && (
          <Flex direction={"column"} gap={"md"}>
            <Text fw={700}>Are you currently pregnant or breastfeeding? </Text>
            <Chip.Group
              multiple={false}
              value={data?.pregBF ? "yes" : "no"}
              onChange={(value) => {
                setData({
                  ...data,
                  pregBF: value === "yes" ? true : false,
                });
              }}>
              <Flex gap={"md"}>
                <Chip radius={"md"} size="xl" variant="filled" value="yes">
                  Yes
                </Chip>
                <Chip radius={"md"} size="xl" variant="filled" value="no">
                  No
                </Chip>
              </Flex>
            </Chip.Group>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

export default Questioner;
