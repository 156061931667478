import moment from "moment";

export const convertTimestamp = (timestamp: any | undefined) => {
  if (!timestamp) return new Date();
  //extract the seconds and nanos values from your Firestore timestamp object
  const { seconds, nanoseconds } = timestamp || {};
  //combine the seconds and nanos values into a single timestamp in milliseconds
  const milliseconds = seconds * 1000 + nanoseconds / 1e6;
  //use Moment.js to convert the timestamp to a date
  return moment(milliseconds).toDate();
};
