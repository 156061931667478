export const PRIVACY_POLICY = `
  <h2>The Art of Medicine, LLC Notice of Privacy Practices</h2>
  <p>THIS PAGE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</p>
  <p>We have created this Notice of Privacy Practices under the Health Insurance Portability and Accountability Act of 1996, known as HIPAA, to describe the ways we may use and disclose your medical information, as well as your related rights and choices.</p>

  <h3>YOUR HEALTH INFORMATION RIGHTS</h3>
  <p>When it comes to your health information, you have certain rights. This section explains your rights and some of our responsibilities to help you.</p>

  <h4>Get an electronic or paper copy of your health information.</h4>
  <p>You can ask to see or get an electronic or paper copy of the prescriptions we have filled for you and any other health information we have about you. To do this, please obtain a “Request for Access to Records” form from the pharmacy and return the completed form to the pharmacy.</p>
  <p>We will provide a copy or a summary of this health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.</p>

  <h4>Ask us to correct your health information.</h4>
  <p>You can ask us to correct health information about you that you think is incorrect or incomplete. To do this, obtain a “Request for Amendment to Health Information” form from the pharmacy and return the completed form to the pharmacy.</p>
  <p>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</p>

  <h4>Request confidential communications.</h4>
  <p>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address. To do this, please obtain a “Request for Alternative Arrangements for Confidential Communication” form from the pharmacy and return the completed form to the pharmacy.</p>
  <p>We will say “yes” to all reasonable requests.</p>

  <h4>Ask us to limit what we use or share.</h4>
  <p>You can ask us not to use or share certain health information for treatment, payment, or our operations. To do this, please obtain a “Request for Restriction of Uses & Disclosures” form from the pharmacy and return the completed form to the pharmacy. We are not required to agree to your request, and we may say “no” if it would affect your care.</p>
  <p>If you pay for a prescription or other health care item out-of-pocket in full, you can ask us not to share that information for the purpose of payment or our operations with your health insurer. We will say “yes” unless a law requires us to share that information.</p>

  <h4>Get a list of those with whom we’ve shared your information.</h4>
  <p>You can ask for a list (an accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why. To do this, please obtain a “Request for Accounting of Disclosures” form from the pharmacy and return the completed form to the pharmacy.</p>
  <p>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</p>

  <h4>Get a copy of this privacy notice.</h4>
  <p>You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will promptly provide you with a paper copy.</p>

  <h4>Choose someone to act for you.</h4>
  <p>If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.</p>
  <p>We will make sure the person has this authority and can act for you before we take any action.</p>

  <h4>File a complaint if you feel your rights have been violated.</h4>
  <p>If you feel we have violated your rights, you can complain to our Privacy Officer, Jack Korbutov, by calling (215) 238-9055 or emailing support@theartofmed.com.</p>
  <p>You can also file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting <a href="http://www.hhs.gov/ocr/privacy/hipaa/complaints/">www.hhs.gov/ocr/privacy/hipaa/complaints/</a>.</p>
  <p>We will not retaliate against you for filing a complaint.</p>

  <h3>YOUR HEALTH INFORMATION CHOICES</h3>
  <p>For certain health information, you can tell us your choices about what we share. If you have a clear preference for how we share your information in the situations described below, talk to us. Tell us what you want us to do, and we will follow your instructions.</p>
  <p>You have both the right and the choice to tell us to:</p>
  <ul>
    <li>Share information with your family, close friends or others involved in your care</li>
    <li>Share information in a disaster relief situation</li>
  </ul>
  <p>If you are not able to tell us your preference, we may go ahead and share your information if we believe it is in your best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.</p>
  <p>Unless you give us written permission, we will never share your information for the following purposes:</p>
  <ul>
    <li>Marketing purposes</li>
    <li>Sale of your information</li>
  </ul>

  <h3>OUR USE AND DISCLOSURE OF YOUR INFORMATION</h3>
  <p>We typically use or share your health information in the following ways.</p>
  <h4>To Treat You.</h4>
  <p>We can use your health information to fill your prescriptions and to remind you when prescriptions are ready to be refilled. We may contact you to notify you of alternative treatments and/or products or to notify you of benefits and services we provide. We can share your information with other professionals who are treating you.</p>

  <h4>To Bill for Our Services.</h4>
  <p>We can use and share your information to bill and get payment from health plans or other entities.</p>

  <h4>To Run Our Organization.</h4>
  <p>We can use and share the minimum necessary information to run the pharmacy, conduct quality assessments and improvement activities, evaluate the pharmacy workforce, and contact you when necessary.</p>

  <p>How else can we use or share your health information? We are allowed or required to share your information in other ways– usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes. For more information see: <a href="http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html</a>.</p>

  <h4>To help with public health and safety activities.</h4>
  <p>We can share information about you for situations such as:</p>
  <ul>
    <li>Preventing disease</li>
    <li>Helping with product recalls</li>
    <li>Reporting adverse reactions to medications</li>
    <li>Reporting suspected abuse, neglect, or domestic violence</li>
    <li>Preventing or reducing a serious threat to anyone’s health or safety</li>
  </ul>

  <h4>To do research.</h4>
  <p>We can use or share your information for health research.</p>

  <h4>To comply with the law.</h4>
  <p>We will share information about you if federal or state laws require it, including with the Department of Health and Human Services if it wants to see that we’re complying with federal privacy law.</p>

  <h4>To respond to organ and tissue donation requests.</h4>
  <p>We can share health information about you with organ procurement organizations.</p>

  <h4>To work with a medical examiner or funeral director.</h4>
  <p>We can share health information with a coroner, medical examiner, or funeral director when an individual dies.</p>

  <h4>To address workers’ compensation, law enforcement and other government requests.</h4>
  <p>We can share health information about you:</p>
  <ul>
    <li>For workers’ compensation claims</li>
    <li>For law enforcement purposes or with a law enforcement official</li>
    <li>With health oversight agencies for activities authorized by law</li>
    <li>For special government functions such as military, national security, and presidential protective services</li>
  </ul>

  <h4>To respond to lawsuits and legal actions.</h4>
  <p>We can share health information about you in response to a court or administrative order, or in response to a subpoena.</p>

  <h3>OUR RESPONSIBILITIES REGARDING YOUR INFORMATION</h3>
  <p>We are required by law to maintain the privacy and security of your protected health information.</p>
  <p>We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.</p>
  <p>We must follow the duties and privacy practices described in this notice and give you a copy of it.</p>
  <p>We will not use or share your information other than as described in this notice unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind. To do this, please obtain a “Request for Restriction of Uses and Disclosures” form from the pharmacy and return the completed form to the pharmacy.</p>
  <p>For more information see: <a href="http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html</a>.</p>

  <h4>Changes to this Notice of Privacy Policies</h4>
  <p>We can change the terms of this Notice of Privacy Practices at any time, and the changes will apply to all information we have about you. The new notice will be available upon request at the pharmacy and on our website.</p>
  <p>This Notice of Privacy Policies is effective as of December 1st, 2023.</p>
`;

export const TERMS_OF_SERVICE = `
<h2>The Art of Medicine, LLC</h2>
<h3>Terms and Conditions</h3>
<p>These Terms and Conditions (these “Terms”) govern all products and services provided by The Art of Medicine, LLC, a compounding pharmacy (the “Pharmacy”), including your use of this website. By purchasing products or services from the Pharmacy, you agree that you have read these Terms and that you agree to be bound by them.</p>
<p>The Pharmacy’s products and services are not offered to individuals under the age of 18. Do not order products or services from the Pharmacy if you are a minor.</p>

<h3>Orders and Beyond-Use Dates</h3>
<p>Upon receipt of a prescription from your health care provider, we will send you a custom order link. You may use the custom order link to process your order, including to provide requested information and to pay for your medication using a debit or credit card. We may contact you if we need additional information to process your order. If you prefer, you may also process your order in person at the Pharmacy, but we cannot begin compounding your medication until we receive payment in full. Upon receipt of your order and payment, we will prepare your medication.</p>
<p>We can only dispense a medication in the quantity that is specified by your health care provider.</p>
<p>We will hold your medication for pick-up at the Pharmacy unless you request that we deliver your medication. All deliveries are made using a third party delivery service, which is solely responsible for delivery times.</p>
<p>Our compounded products are made in compliance with Chapter 795 of the United States Pharmacopeia, which specifies for each compounded medication a “beyond-use date” after which the medication must not be used due to risks such as physical or chemical degradation or microbial contamination. You are responsible for arranging pick-up or delivery of your medication within the “beyond-use date” restriction.</p>

<h3>No Returns</h3>
<p>DUE TO THE NATURE OF OUR PRODUCTS, WE CANNOT ACCEPT RETURNS.</p>
<p>The Pharmacy is licensed by the Pennsylvania State Board of Pharmacy and registered with the U.S. Food & Drug Administration. We prepare, label, sell and dispense prescribed products and services in accordance with all applicable federal and Pennsylvania laws.</p>
<p>In compliance with Pennsylvania pharmacy law, we cannot accept returns of medications. This means:</p>
<ul>
  <li>We issue no charge backs, reversals, cash refunds or credits. Once you have paid for your medication, the transaction is final. We cannot issue a charge back, reverse your payment, provide a cash refund or give you a credit. Even if you offer to return the medication to us, we cannot accept it.</li>
  <li>Payment is due even if you fail to pick up your medication. If you ask us to prepare your medication for pick-up, the charge to your credit/debit card is final. We cannot return compounded medications to stock, so we cannot offer refunds, chargebacks, reversals or credits to patients who fail to pick up their medication.</li>
  <li>Payment is due even if you refuse delivery of your medication. Once your medication has left our premises, the transaction is final. Medications that we have given to the delivery service cannot be returned to stock, so we cannot offer refunds, chargebacks, reversals or credits to patients who are not at home when delivery is attempted or who fail to accept a scheduled delivery of their medication.</li>
  <li>Payment is due even if the “beyond-use date” expires before you receive your medication. We cannot dispense – through delivery or pick-up – any compounded medication after its “beyond-use date.” Please arrange for prompt pick-up or delivery after placing your order, as medications in our possession after their “beyond-use date” will be discarded without refund, chargeback, reversal or credit.</li>
</ul>

<h3>Your Rights</h3>
<p>When receiving products and services from the Pharmacy, you have the following rights:</p>
<ul>
  <li>To be treated with friendliness, courtesy, and respect by each individual representing the Pharmacy with whom you come in contact.</li>
  <li>To receive the prescribed product or service in a professional manner without discrimination based on age, sex, race, religion, ethnic origin, sexual preference or physical or mental handicap.</li>
  <li>To be promptly informed if the prescribed product or service is not within the scope of service of the Pharmacy, in which case you will be provided with transfer assistance to an appropriate care or service organization.</li>
  <li>To be provided with adequate information for you to give your informed consent for the commencement of the prescribed product or service, the continuation of the prescribed product or service, the transfer of the prescribed product or service to another health care provider, or termination of the prescribed product or service.</li>
  <li>To be informed of any experimental or investigational product or service that is involved in your care and to be provided the right to refuse any such product or service.</li>
  <li>To be informed in advance of any charges for which you will be responsible.</li>
  <li>To request and receive information regarding prescribed products and services, or the costs thereof, privately and with confidentiality.</li>
  <li>To receive information regarding any financial relationships between the Pharmacy and other providers.</li>
  <li>To have your privacy respected in accordance with our Notice of Privacy Practices.</li>
  <li>To express concerns or grievances or without fear of discrimination or reprisal.</li>
  <li>To speak with a Pharmacy supervisor upon request.</li>
</ul>

<h3>Your Responsibilities</h3>
<p>When receiving products and services from the Pharmacy, you have the following responsibilities:</p>
<ul>
  <li>To follow instructions given to you by the Pharmacy for the prescribed products and services and to ask any questions you may have regarding such products and services.</li>
  <li>To evaluate your home environment and make any adjustments needed for safe medication handling and storage.</li>
  <li>To arrange for prompt pick-up or delivery of your medication, before its “beyond-use date.”</li>
  <li>To notify the delivery service if you will not be home at the time of a scheduled delivery.</li>
  <li>Upon request, to provide accurate information to the Pharmacy concerning your present health status, current medications, and allergies.</li>
  <li>To meet financial commitments resulting from the products and services provided by the Pharmacy.</li>
  <li>To treat the Pharmacy’s representatives with courtesy and respect.</li>
  <li>To notify the Pharmacy when you encounter any problem with a prescribed product or service.</li>
  <li>To notify your health care provider when you encounter any unusual physical or mental condition which may result from the products or services being provided by the Pharmacy.</li>
  <li>To notify the Pharmacy if your health care provider modifies or stops your prescribed product or service.</li>
</ul>

<h3>Website Use</h3>
<p>If you wish to use this website to purchase products or services provided by the Pharmacy, you may be asked to register. When you do so, you will choose a username (which may be your email address) as well as a password. You must not share such identifying information with any third party. If you discover that your username or password has been compromised, you agree to notify the Pharmacy immediately by email at support@theartofmed.com. You are responsible for maintaining the safety and security of your identifying information.</p>

<h3>Acceptable Use; Spam Policy</h3>
<p>You agree not to use this website for any unlawful purpose or in any way that could damage this website or the business of the Pharmacy. Without limiting the foregoing, you agree not to use this website:</p>
<ul>
  <li>To publish in any media, sell, license or otherwise commercialize any material from this website.</li>
  <li>To publicly show any material from this website in any way that is, or may be, damaging to the Pharmacy.</li>
  <li>To adversely impact the access of any other user of this website.</li>
  <li>To violate applicable laws or regulations or to cause harm to the Pharmacy or any other person or entity.</li>
  <li>To engage in any data mining, data harvesting, data extracting or similar activity or otherwise to unlawfully gather information about others.</li>
  <li>To engage in any advertising or marketing.</li>
  <li>To harass, abuse, or threaten others or otherwise violate any person's legal rights.</li>
  <li>To violate any intellectual property rights of the Pharmacy or of any third party.</li>
  <li>To upload to this website or otherwise to disseminate through this website any computer viruses or other software that may damage the property of another.</li>
  <li>To perpetrate any fraud.</li>
  <li>To publish or distribute any obscene or defamatory material.</li>
  <li>To publish or distribute any material that incites violence, hate, or discrimination towards any group.</li>
</ul>
<p>You are strictly prohibited from using this website for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails. We reserve the right to immediately terminate your access to this website if we believe you have violated these restrictions.</p>
<p>We may restrict access by you to any areas of this website, at any time, in our sole and absolute discretion.</p>

<h3>Intellectual Property Rights</h3>
<p>The Pharmacy and/or its licensors own all rights to the material contained in this website and related intellectual property rights, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.</p>

<h3>Reverse Engineering and Security</h3>
<p>You agree not to (i) reverse engineer or disassemble, or attempt to reverse engineer or disassemble, any code or software from or on this website; or (ii) violate the security of this website through any circumvention of encryption or other security tools or interference with any host, user or network.</p>

<h3>Data Loss</h3>
<p>The Pharmacy accepts no responsibility for the security of your account or content. You agree that your use of this website is at your own risk.</p>

<h3>Third Party Links</h3>
<p>The Pharmacy may occasionally post on this website links to third party websites. You agree that the Pharmacy is not responsible or liable for any loss or damage resulting from of your use of any third party products or services accessed through such a link on this website.</p>

<h3>Service Interruptions</h3>
<p>The Pharmacy may interrupt your access to this website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to this website may be affected by unanticipated or unscheduled downtime, for any reason, but that the Pharmacy will have no liability for any damage or loss resulting from such downtime.</p>

<h3>No Warranties</h3>
<p>This website is provided “as is,” with all faults. The Pharmacy hereby expressly disclaims any and all express or implied warranties of any kind, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement. The Pharmacy makes no warranties that this website will meet your needs or that this website will be uninterrupted, error-free, or secure. The Pharmacy also makes no warranties as to the reliability or accuracy of any information on this website or obtained through this website. You agree that any damage that may occur to you through your computer system or as a result of loss of your data from your use of this website is your sole responsibility and that the Pharmacy is not liable for any such damage or loss.</p>

<h3>Limitation of Liability</h3>
<p>In no event shall the Pharmacy or any of its directors, officers, managers, affiliates, employees or agents be liable for any loss, damage, liability, cost or expense arising out of or connected with your use of this website, whether arising under contract, tort or otherwise, nor shall any of them be liable for any indirect, consequential, special or punitive damage arising out of or connected with your use of this website.</p>

<h3>Indemnification</h3>
<p>You agree to defend, indemnify and hold harmless the Pharmacy and its directors, officers, managers, affiliates, employees and agents against all claims, demands, causes of action, liabilities, costs, damages and expenses (including reasonable attorney's fees) that may arise out of or in connection with your use or misuse of this website, your breach of these Terms, or your other conduct, action or inaction. Notwithstanding the foregoing, you agree that the Pharmacy may participate in its own defense with counsel of its own choosing, if the Pharmacy wishes.</p>

<h3>Severability</h3>
<p>If any provision of these Terms is found to be unenforceable or invalid under applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

<h3>Modification</h3>
<p>You acknowledge and agree that the Pharmacy may, from time to time and at any time without notice to you, modify this website and/or these Terms. All such modifications shall take effect immediately upon posting by the Pharmacy on this website. You agree that your continued use of this website after any such modification is a manifestation of your agreement to continue to be bound by these Terms as so modified.</p>

<h3>Entire Agreement</h3>
<p>These Terms constitute the entire agreement between the Pharmacy and you with respect to their subject matter and supersede all prior agreements and understandings with respect to such subject matter.</p>

<h3>Governing Law and Jurisdiction</h3>
<p>These Terms are governed by, and shall be construed in accordance with, the laws of the Commonwealth of Pennsylvania. You submit to the non-exclusive jurisdiction of the state and federal courts located in Pennsylvania for the resolution of any disputes in connection herewith. The Parties hereby waive any rights they may have to a jury trial in connection with any such dispute.</p>

<h3>Concerns and Complaints</h3>
<p>If you feel that we are not abiding by these Terms, please contact us immediately by telephone at (215) 238-9055, by email at support@theartofmed.com, or by website form on our “Support” page.</p>

<p>These Terms and Conditions are effective as of December 1st, 2023.</p>
`;
