import { Card, Flex, Space, Text } from "@mantine/core";

function EndGreetings() {
  return (
    <Card
      radius={"md"}
      className="responsive-card"
      mih={"40vh"}
      display={"flex"}
      p={15}>
      <Flex direction={"column"} justify={"space-between"} h={"100%"} gap={20}>
        <Flex direction={"column"}>
          <Text fw={700}>Thank You!</Text>
          <Space h="md" />
          <Text>
            We’ll send you a confirmation email in just a minute. We’ll then
            process your order and email a receipt, along with follow ups to
            help you track your order. If you ever have a question, feel free to
            text the number with the link, or call us at (215) 238-9055.
          </Text>
          <Space h="md" />
        </Flex>
      </Flex>
    </Card>
  );
}

export default EndGreetings;
