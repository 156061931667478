import { Button, Flex } from "@mantine/core";
import React from "react";
import styled from "styled-components";

interface Props {
  onConfirm?: (() => Promise<void>) | (() => void);
  onCancel?: () => void;
  onClose?: () => void;
  title: string;
  confirmText?: string;
  cancelText?: string;
  message?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  confirmLoading?: boolean;
  cancelDisabled?: boolean;
}

function ConfirmModalFrame({
  onConfirm,
  onCancel,
  onClose = () => {},
  title,
  message,
  confirmText = "Yes",
  cancelText = "No",
  children,
  style = {},
  confirmLoading,
  cancelDisabled = false,
}: Props) {
  return (
    <Flex justify={"center"}>
      <Container>
        <Text>{title}</Text>

        {children}

        <Flex gap={10} w={"100%"}>
          {onCancel ? (
            <Button
              flex={1}
              size="lg"
              radius={"md"}
              onClick={() => {
                onCancel();
                onClose();
              }}
            >
              {cancelText}
            </Button>
          ) : null}

          {onConfirm ? (
            <Button
              flex={1}
              radius={"md"}
              size="lg"
              loading={confirmLoading}
              onClick={async () => {
                await onConfirm();
                onClose();
              }}
              disabled={cancelDisabled}
            >
              {confirmText}
            </Button>
          ) : null}
        </Flex>

        {message ? <Message>{message}</Message> : null}
      </Container>
    </Flex>
  );
}

export default ConfirmModalFrame;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
  border-radius: 20px;
  padding: 24px;
  padding-top: 40px;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #34333b;
  margin-bottom: 20px;
`;

const Message = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 15px;
`;
