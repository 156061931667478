const assetPath = "/assets/images/";
const images = {
  logo: `${assetPath}artofmedicine-logo.svg`,
  medicationCream: `${assetPath}medication-cream.svg`,
  medicationTablet: `${assetPath}medication-tablet.svg`,
};
const icons = {};

const assets = {
  images,
  icons,
};

export default assets;
