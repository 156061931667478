import { MantineColorsTuple } from "@mantine/core";

const primaryColor: MantineColorsTuple = [
  "#f6edff",
  "#e6d6fa",
  "#c9aaf1",
  "#ab7be8",
  "#9154e0",
  "#813adc",
  "#792eda",
  "#6721c2",
  "#5c1cae",
  "#4f1599",
];

let primColor = "#6E23CF";

const colors = {
  primaryColor,
};

export default colors;
