import { COLLECTIONS } from "../../../constants/types";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Image,
  Text,
} from "@mantine/core";
import {
  BIRD_RODENT_FLAVOUR,
  CAT_MED_FLAVOUR,
  CAT_TREAT_FLAVOUR,
  DOG_MED_FLAVOUR,
  DOG_TREAT_FLAVOR,
  HUMAN_MED_FLAVOUR,
  assets,
} from "../../../constants";
import styled from "styled-components";
import { modals } from "@mantine/modals";
import ConfirmModalFrame from "../../../components/ConfirmModalFrame";
import { useAppData } from "../../../contexts/AppContext";
import { Fragment } from "react";
import { upsertDoc } from "../../../repositories/firebase";
import { BatchRuleActionType } from "../../../constants/enums";

const whichOptions = (metadata: any) => {
  if (metadata![BatchRuleActionType.HumanMedicineFlavour]) {
    return HUMAN_MED_FLAVOUR;
  } else if (metadata![BatchRuleActionType.DogMedicineFlavour]) {
    return DOG_MED_FLAVOUR;
  } else if (metadata![BatchRuleActionType.DogTreatFlavour]) {
    return DOG_TREAT_FLAVOR;
  } else if (metadata![BatchRuleActionType.CatMedicineFlavour]) {
    return CAT_MED_FLAVOUR;
  } else if (metadata![BatchRuleActionType.CatTreatFlavour]) {
    return CAT_TREAT_FLAVOUR;
  } else if (metadata![BatchRuleActionType.RodentBirdMedicineFlavour]) {
    return BIRD_RODENT_FLAVOUR;
  } else return [];
};

interface Props {
  editable?: boolean;
}

function MedicationCard(props: Props) {
  const { editable = true } = props;
  const { data, setData, order } = useAppData();

  const openModal = (med: any) => {
    if (data.medications.length <= 1) {
      modals.open({
        title: "Cannot Remove Last Item",
        children: (
            <Text>You cannot remove the last item from your order. At least one item must remain.</Text>
        ),
        onClose: () => {},
        closeButtonProps: {
          display: "none",
        },
        centered: true,
        styles: {
          header: {
            display: "none",
          },
        },
      });
      return;
    }
    modals.open({
      children: (
        <ConfirmModalFrame
          title={`Are you sure you want to remove ${med.itemName} from your order?`}
          onConfirm={() => {
            modals.close("confirm-action");
            setData({
              ...data,
              medications: data.medications.filter(
                (m) => m.itemName !== med.itemName
              ),
            });
          }}
          onCancel={() => {
            modals.close("confirm-action");
          }}
        />
      ),
      onClose: () => {},
      modalId: "confirm-action",
      closeButtonProps: {
        display: "none",
      },
      centered: true,
      title: "Confirm remove",
      styles: {
        header: {
          display: "none",
        },
      },
    });
  };

  const confirmAutoRefill = (checked: boolean, medName: string) => {
    modals.open({
      children: (
        <ConfirmModalFrame
          title={`Are you sure you want to ${
            checked ? " enable" : "disable"
          } Auto Refill for this order?`}
          onConfirm={async () => {
            modals.close("confirm-action");
            let meds = data.medications.map((m) => {
              if (m.itemName === medName) {
                return {
                  ...m,
                  autoRefill: checked,
                };
              } else return m;
            });
            await upsertDoc(
              COLLECTIONS.ORDERS,
              {
                medications: meds,
              },
              order?.id
            );
            setData((prev: any) => {
              return { ...prev, medications: meds };
            });
          }}
          onCancel={() => {
            modals.close("confirm-action");
          }}
        />
      ),
      onClose: () => {},
      modalId: "confirm-action",
      closeButtonProps: {
        display: "none",
      },
      centered: true,
      title: "Confirm",
      styles: {
        header: {
          display: "none",
        },
      },
    });
  };

  return (
    <Flex direction={"column"} gap={15}>
      {editable ? <Header>{data.name} Medication's </Header> : null}

      <Flex direction={"column"} gap={15}>
        {data.medications.map((med) => {
          let image =
            med.dosageForm === "cream"
              ? assets.images.medicationCream
              : assets.images.medicationTablet;

          return (
            <Card key={med.itemName} mr={10}>
              <Flex justify={"space-between"} align={"center"}>
                <Flex gap={10}>
                  <Image
                    className="responsive-image"
                    w={editable ? 106 : 95}
                    h={editable ? 121 : 80}
                    radius={"md"}
                    src={image}
                  />
                  <Flex direction={"column"} justify={"space-around"}>
                    <Paragraph2>{med.itemName}</Paragraph2>
                    <Paragraph2>{med.dosageForm}</Paragraph2>
                    <Flex gap={20} wrap={"wrap"}>
                      <div>Qty:</div>
                      {editable ? (
                        <Flex gap={"sm"} wrap={"wrap"}>
                          <select
                            style={{
                              width: 80,
                            }}
                            value={med.quantity}
                            onChange={(e) => {
                              let newMeds = data.medications.map((m) => {
                                if (m.itemName === med.itemName) {
                                  return {
                                    ...m,
                                    quantity: parseFloat(e.target.value),
                                    price:
                                      parseFloat(e.target.value) ==
                                      parseFloat(med.alt_qty || "0")
                                        ? med?.alt_price
                                        : med?.ogPrice,
                                  };
                                } else return m;
                              }) as any[];

                              let newInitialAmount = newMeds
                                ?.filter(
                                  (med) =>
                                    !med.metadata![
                                      BatchRuleActionType.NoPayment
                                    ]
                                )
                                ?.reduce(
                                  (acc, med) =>
                                    acc +
                                    parseFloat(med.price.replace(/^\D+/g, "")),
                                  0
                                );
                              let shippingAmount = 0;
                              if (
                                data.shippingOption &&
                                !isNaN(parseFloat(data.shippingOption))
                              ) {
                                shippingAmount = parseFloat(
                                  data.shippingOption
                                );
                              }
                              setData({
                                ...data,
                                medications: newMeds,
                                initialAmount: newInitialAmount + 1.5,
                                totalAmount:
                                  newInitialAmount +
                                  (data.rushOrder ? 25 : 0) +
                                  shippingAmount +
                                  1.5,
                              });
                            }}>
                            <option value={med.ogQty}>{med.ogQty}</option>
                            {med?.alt_qty ? (
                              <option value={med?.alt_qty}>
                                {med?.alt_qty}
                              </option>
                            ) : null}
                          </select>

                          {whichOptions(med?.metadata || {}).length ? (
                            <select
                              value={med.flavour}
                              onChange={(e) => {
                                let newMeds = data.medications.map((m) => {
                                  if (m.itemName === med.itemName) {
                                    return {
                                      ...m,
                                      flavour: e.target.value || "",
                                    };
                                  } else return m;
                                }) as any[];
                                setData({
                                  ...data,
                                  medications: newMeds,
                                });
                              }}>
                              {whichOptions(med?.metadata).map((opt) => {
                                return (
                                  <option value={opt.value}>{opt.label}</option>
                                );
                              })}
                            </select>
                          ) : null}
                        </Flex>
                      ) : (
                        <Fragment>{med.quantity}</Fragment>
                      )}
                    </Flex>
                    {editable ? (
                      <Button
                        onClick={() => openModal(med)}
                        size="compact-sm"
                        variant="transparent"
                        style={{
                          textDecoration: "underline",
                          width: "auto",
                          alignSelf: "start",
                          padding: 0,
                        }}>
                        Remove
                      </Button>
                    ) : null}
                  </Flex>
                </Flex>
                <Flex
                  direction={"column"}
                  py={10}
                  justify={"space-between"}
                  align={"flex-end"}
                  h={"100%"}>
                  {med?.metadata &&
                  med?.metadata![BatchRuleActionType.NoPayment] ? (
                    <Badge size="xs">No payment</Badge>
                  ) : (
                    <Fragment>
                      {med?.alt_price &&
                      med.quantity === parseFloat(med?.alt_qty || "") ? (
                        <Flex gap={"sm"}>
                          <div
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "red",
                            }}>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              parseFloat(med.ogPrice.replace(/[^\d.-]/g, ""))
                            )}
                          </div>
                          <div>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              parseFloat(med.alt_price.replace(/[^\d.-]/g, ""))
                            )}
                          </div>
                        </Flex>
                      ) : (
                        <div>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(
                            parseFloat(med.ogPrice.replace(/[^\d.-]/g, ""))
                          )}
                        </div>
                      )}
                    </Fragment>
                  )}

                  {editable ? (
                    <Fragment>
                      {med?.isAutoRefillVisible && (
                        <Checkbox
                          label="Auto-Refill?"
                          checked={med?.autoRefill}
                          onChange={(e) => {
                            confirmAutoRefill(e.target.checked, med.itemName);
                          }}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Text>{med?.isAutoRefillVisible ? "Auto-Refill" : ""}</Text>
                  )}
                </Flex>
              </Flex>
            </Card>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default MedicationCard;

const Header = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.011em;
  margin-top: 10px;
`;
const Paragraph2 = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
