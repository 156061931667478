import { useState } from "react";
import { Step, STEPS_ORDER } from "../constants/types";

export interface IUseAppFlow {
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  currentStep: Step;
  hasNextStep: () => boolean;
  hasPrevStep: () => boolean;
  removeStepFromOrder: (step: Step) => void;
  restoreStepsOrder: () => void;
}

export const useAppFlow = (): IUseAppFlow => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.AddPatient);
  const [stepsOrder, setStepsOrder] = useState<Step[]>(STEPS_ORDER);

  const removeStepFromOrder = (step: Step) =>
    setStepsOrder(STEPS_ORDER.filter((s) => s !== step));
  const restoreStepsOrder = () => setStepsOrder(STEPS_ORDER);
  const goToNextStep = () => {
    const currentIndex = stepsOrder.indexOf(currentStep);
    const nextIndex = currentIndex + 1;

    if (nextIndex < stepsOrder.length) {
      setCurrentStep(stepsOrder[nextIndex]);
    }
  };

  const goToPreviousStep = () => {
    const currentIndex = stepsOrder.indexOf(currentStep);
    const prevIndex = currentIndex - 1;

    if (prevIndex >= 0) {
      setCurrentStep(stepsOrder[prevIndex]);
    }
  };

  const hasNextStep = () => stepsOrder.indexOf(currentStep) < stepsOrder.length;

  const hasPrevStep = () => stepsOrder.indexOf(currentStep) > 0;

  return {
    goToPreviousStep,
    goToNextStep,
    currentStep,
    hasNextStep,
    hasPrevStep,
    removeStepFromOrder,
    restoreStepsOrder,
  };
};
