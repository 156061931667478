import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { IUseAppFlow, useAppFlow } from "../hooks/useAppFlow";
import { COLLECTIONS, IData, Member } from "../constants/types";
import { QUESTIONS } from "../constants";
import { UsePatients, usePatients } from "../hooks/usePatient";
import { Order } from "../constants/collections";
import { useSearchParams } from "react-router-dom";
import { BatchRuleActionType, Specie } from "../constants/enums";
import { getDocByQuery } from "../repositories/firebase";
import { whichShippingOptions } from "../pages/Home/sections/AddressDetails";

interface IAppData {
  appFlow: IUseAppFlow;
  data: IData;
  setData: React.Dispatch<React.SetStateAction<IData>>;
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  patientsData: UsePatients;
  order: Order | undefined;
  setOrder: any;
  user: any;
  disableContinue: {
    [key: string]: boolean;
  };
  setDisableContinue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  batchPreview: any;
}

export const AppDataContext = createContext<IAppData>({} as IAppData);

export function useAppData() {
  return useContext(AppDataContext);
}

export default function AppDataProvider(props: PropsWithChildren<any>) {
  const appFlowData = useAppFlow();
  const [authenticated, setAuthenticated] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [batchPreview, setBatchPreview] = useState<any>();
  const [searchParams] = useSearchParams();
  const patientsData = usePatients();
  debugger;
  let orderInUrl = [] as any;
  if(searchParams.get("oid")) {
    orderInUrl = patientsData.orders?.find(
        (p) => p.id === searchParams.get("oid")
    );
  }
  if(searchParams.get("uid")) {
    orderInUrl = patientsData.orders?.find(
        //(p) => p.id === searchParams.get("uid")
        (p : any) => p.userId === searchParams.get("uid")
    );
  }

  let user = patientsData.patients?.find(
    //(p) => p.id === searchParams.get("uid")
    (p) => p.id === orderInUrl?.userId
  );

  const [data, setData] = useState<IData>({} as IData);
  const [disableContinue, setDisableContinue] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    let initialAmount =
      (order?.medications
        ?.filter((med) => !med.metadata![BatchRuleActionType.NoPayment])
        ?.reduce(
          (acc, med) => acc + parseFloat(med.price.replace(/^\D+/g, "")),
          0
        ) || 0) + 1.5;
    let shippingOption = whichShippingOptions(order)![0];
    let shippingCost =
      order?.shippingOption && !isNaN(parseFloat(order?.shippingOption))
        ? parseFloat(order?.shippingOption)
        : 0;

    let rushOrderCost = order?.rushOrder ? 25 : 0;

    setData({
      birthday: data?.birthday || undefined,
      phone: user?.phone || "",
      name: (user?.firstName || "") + "  " + (user?.lastName || ""),
      medications:
        order?.medications?.map((med) => ({
          ...med,
          ogQty: med.quantity,
          ogPrice: med.price,
        })) || [],
      email: user?.email || "",
      internalNotes: "",  //retired
      notes: user?.notes || "",
      patients: [],
      type:
        user?.species?.toLowerCase() === Specie.human
          ? Member.Human
          : Member.Pet,
      questions: [
        ...(order?.questions || QUESTIONS),
        ...(order?.metadata &&
        order?.metadata![BatchRuleActionType.CheckForPregnantBreastfeeding]
          ? []
          : []),
      ],
      addresses: {
        shippingAddress: {
          email: user?.email || "",
          addressLine1: user?.address?.street || "",
          addressLine2: user?.address?.street2 || "",
          city: user?.address?.city || "",
          state: user?.address?.state || "",
          zipCode: user?.address?.postalCode || undefined,
        },
        bothSame: user?.sameAddress || false,
        billingAddress: {
          email: user?.email || "",
          addressLine1: user?.billingAddress?.street || "",
          addressLine2: user?.billingAddress?.street2 || "",
          city: user?.billingAddress?.city || "",
          state: user?.billingAddress?.state || "",
          zipCode: user?.billingAddress?.postalCode || undefined,
        },
      },
      paymentInfo: {
        firstName: order?.paymentMethod?.cardHolderName.split(" ")![0] || "",
        lastName: order?.paymentMethod?.cardHolderName.split(" ")![1] || "",
        creditCardNumber: order?.paymentMethod?.cardNumber || "",
        securityCode: order?.paymentMethod?.securityCode || "",
        expiryMonth: order?.paymentMethod?.expirationMonth || "",
        expiryYear: order?.paymentMethod?.expirationYear || "",
        zipCode: order?.paymentMethod?.zipCode || "",
      },
      pharmacyCall: false,
      onHold: false,
      acknowledgment: false,
      signature: "",
      flavour: order?.flavour || "",
      shippingOption: order?.shippingOption || "",
      termsAgree: false,
      rushOrder: order?.rushOrder,
      totalAmount: initialAmount + shippingCost + rushOrderCost,
      initialAmount,
      pregBF: order?.pregBF || false,
      signatureWaived: order?.signatureWaived || false,
      originalAmount: 0,
    });
  }, [searchParams, order]);

  useEffect(() => {
    if (order && order.rxNumber) {
      (async () => {
        const batch_preview = await getDocByQuery<any>(
          COLLECTIONS.BATCH_PREVIEW,
          "rxNumber",
          order.rxNumber
        );

        setBatchPreview({
          ...batch_preview,
          metadata: batch_preview?.metadata || {},
        });
      })();
    }
  }, [order]);

  useEffect(() => {
    if (user) {
      setData({
        ...data,
        type:
          user?.species?.toLowerCase() === Specie.human
            ? Member.Human
            : Member.Pet,
      });
    }
  }, [user]);

  return (
    <AppDataContext.Provider
      value={{
        appFlow: appFlowData,
        data,
        setData,
        authenticated,
        setAuthenticated,
        patientsData,
        order,
        setOrder,
        disableContinue,
        setDisableContinue,
        batchPreview,
        user,
      }}>
      {props.children}
    </AppDataContext.Provider>
  );
}
