import colors from "./theme";
import assets from "./assets";

export const BILL_SUMMARY = [
  {
    name: "Service Fee",
    price: "1.50",
  },
];
export const QUESTIONS = [
  {
    question: "Do you have any drug allergies?",
    placeHolder: "Separate allergies with a comma",
    answer: "",
    none: false,
  },
  {
    question: "Please list any existing medical conditions.",
    placeHolder: "Separate medical conditions with a comma",
    answer: "",
    none: false,
  },
  {
    question: "Please list existing medications.",
    placeHolder: "Separate existing medications with a comma",
    answer: "",
    none: false,
  },
];

export const HUMAN_MED_FLAVOUR = [
  { label: "Strawberry", value: "Strawberry" },
  { label: "Tutti-frutti", value: "Tutti-frutti" },
  { label: "Banana", value: "Banana" },
  { label: "Lemon", value: "Lemon" },
  { label: "Marshmallow", value: "Marshmallow" },
  { label: "Peppermint", value: "Peppermint" },
];
export const DOG_TREAT_FLAVOR = [
  { label: "Chicken & cheesy bacon", value: "ChickenCheesyBacon" },
  { label: "Beef & peanut butter", value: "BeefPeanutButter" },
];
export const DOG_MED_FLAVOUR = [
  { label: "Chicken", value: "Chicken" },
  { label: "Beef", value: "Beef" },
  { label: "Bacon", value: "Bacon" },
];
export const CAT_TREAT_FLAVOUR = [
  { label: "Chicken", value: "Chicken" },
  { label: "Fish", value: "Fish" },
];
export const CAT_MED_FLAVOUR = [
  { label: "Chicken", value: "Chicken" },
  { label: "Fish", value: "Fish" },
  { label: "Marshmallow", value: "Marshmallow" },
];
export const BIRD_RODENT_FLAVOUR = [
  { label: "Strawberry", value: "Strawberry" },
  { label: "Tutti-Frutti", value: "Tutti-Frutti" },
  { label: "Banana", value: "Banana" },
];

export const SHIPPING_UNDER_100 = [
  // { label: "USPS $9", value: "9" },
  { label: "Overnight UPS $33", value: "33" },
  { label: "UPS Ground $14 (1-3 day transit)", value: "14" },
  { label: "Pick Up", value: "pick-up" },
];

export const SHIPPING_UNDER_100_NO_RULE = [
  { label: "Overnight UPS $33", value: "33" },
  { label: "USPS $9 (3-7 day transit)", value: "9" },
  { label: "UPS Ground $14 (1-3 day transit)", value: "14" },
  { label: "Pick Up", value: "pick-up" },
];
export const SHIPPING_100_OR_GREATER = [
  // { label: "Free USPS", value: "0" },
  { label: "Overnight UPS $33", value: "33" },
  { label: "UPS Ground $9 (1-3 day transit)", value: "9" },
  { label: "Pick Up", value: "pick-up" },
];

export const SHIPPING_100_OR_GREATER_NO_RULE = [
  { label: "Free USPS (3-7 day transit)", value: "0" },
  { label: "UPS Ground $9 (1-3 day transit)", value: "9" },
  { label: "Overnight UPS $33", value: "33" },
  { label: "Pick Up", value: "pick-up" },
];

export const SHIPPING_OVERNIGHT_UPS = [
  { label: "Overnight UPS $33", value: "33" },
  { label: "Pick Up", value: "pick-up" },
];

export const PICK_UP = [{ label: "Pick Up", value: "pick-up" }]; // pick with all options
export const USP = [{ label: "USP", value: "usp" }]; // based on shiping under 0100 {9 $ usp and 14 $ ups}

//and 100 or greater {free for usp and 9$ for ups}

export { colors, assets };
