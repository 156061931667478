import { Fragment, useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Space,
  Text,
} from "@mantine/core";
import {
  IHuman,
  IMedication,
  IPet,
  Member,
  Step,
} from "../../../constants/types";
import { IconPlus } from "@tabler/icons-react";
import { useAppData } from "../../../contexts/AppContext";
import { DateInput } from "@mantine/dates";
import "react-phone-input-2/lib/material.css";
import { useForm } from "@mantine/form";

const MED: IMedication = {
  id: "11",
  autoRefill: false,
  dosage: "20mg",
  name: "Berberine GT",
  price: 10.0,
  quantity: "30",
  type: "tablet",
};

const PET_INIT: IPet = {
  id: "1",
  name: "",
  dob: new Date(),
  medications: [MED],
};
const HUMAN_INIT = {
  id: "1",
  firstName: "",
  lastName: "",
  name: "",
  dob: undefined,
  medications: [MED],
};

function AddPatient() {
  const { data, setData, setDisableContinue } = useAppData();

  const form = useForm<any>({
    initialValues: {
      email: "",
      termsAgree: false,
    },
    validate: {
      termsAgree: (value) =>
        !value ? "Please agree to the terms of service/privacy policy." : null,
    },
  });

  useEffect(() => {
    const emailIsValid = (email: string) =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    let shouldBeDisabled = !data.email || !emailIsValid(data.email);
    setDisableContinue({
      [Step.AddPatient]: shouldBeDisabled,
    });
  }, [data, form.values]);

  const [expectingNewMember, setExpectingNewMember] = useState<
    boolean | undefined
  >(undefined);

  const [newMemberType, setNewMemberType] = useState<Member | undefined>(
    undefined
  );

  const [pets, setPets] = useState<IPet[]>([PET_INIT]);
  const [humans, setHumans] = useState<IHuman[]>([HUMAN_INIT]);
  useEffect(() => {
    setData((d) => {
      return {
        ...d,
        patients: pets,
      };
    });
  }, [pets]);

  useEffect(() => {
    setData((d) => {
      return {
        ...d,
        patients: humans,
      };
    });
  }, [humans]);

  useEffect(() => {
    if (newMemberType) {
      setData((d) => {
        return {
          ...d,
          type: newMemberType,
        };
      });
    }
  }, [newMemberType]);

  const addPet = () =>
    setPets([
      ...pets,
      { ...PET_INIT, id: pets.length + 1, medications: [MED] },
    ]);
  const addHuman = () =>
    setHumans([
      ...humans,
      { ...HUMAN_INIT, id: humans.length + 1, medications: [MED] },
    ]);

  const onChangeHuman = (key: string, value: any, id: number | string) => {
    setHumans(
      humans.map((human) => {
        if (human.id == id) {
          return {
            ...human,
            [key]: value,
            name: human.firstName + " " + human.lastName,
          };
        } else return human;
      })
    );
  };

  const onChangePet = (key: string, value: any, id: number | string) => {
    setPets(
      pets.map((pet) => {
        if (pet.id == id) {
          return {
            ...pet,
            [key]: value,
          };
        } else return pet;
      })
    );
  };
  const isNewMember = (
    expecting: boolean | undefined,
    newMemberType: Member | undefined
  ) => {
    if (expecting === undefined) {
      return <></>;
    } else if (expecting) {
      if (!newMemberType) {
        return (
          <Fragment>
            <Text>Do you have an additional family member?</Text>
            <Flex maw={"400px"} gap={10} mt={10} justify={"space-between"}>
              <Button
                onClick={() => setNewMemberType(Member.Human)}
                radius={"md"}
                flex={1}
                size="lg">
                Human
              </Button>
              <Button
                onClick={() => setNewMemberType(Member.Pet)}
                radius={"md"}
                flex={1}
                size="lg">
                Pet
              </Button>
            </Flex>
          </Fragment>
        );
      } else {
        if (newMemberType === Member.Pet) {
          return (
            <Fragment>
              <Text>Additional family member:</Text>
              <Flex
                style={{ flexWrap: "wrap" }}
                gap={10}
                mt={10}
                align={"center"}>
                {pets.map((pet) => (
                  <Input
                    key={pet.id}
                    value={pet.name}
                    onChange={(e) =>
                      onChangePet("name", e.target.value, pet.id)
                    }
                    radius={"md"}
                    maw={"500px"}
                    mt={5}
                    size="lg"
                    styles={{
                      input: {
                        borderColor: "#6E23CF",
                        borderWidth: "3px",
                      },
                    }}
                    placeholder="Pet's name"
                  />
                ))}

                <ActionIcon
                  style={{ borderWidth: 3 }}
                  radius={"md"}
                  h={50}
                  w={50}
                  mt={3}
                  variant="outline"
                  onClick={addPet}>
                  <IconPlus />
                </ActionIcon>
              </Flex>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Text>Additional family member:</Text>
              <Flex
                style={{ flexWrap: "wrap" }}
                gap={10}
                mt={10}
                direction={"column"}>
                {humans.map((human) => (
                  <Flex key={human.id} gap={5}>
                    <Input
                      value={human.firstName}
                      onChange={(e) =>
                        onChangeHuman("firstName", e.target.value, human.id)
                      }
                      radius={"md"}
                      mt={5}
                      size="lg"
                      styles={{
                        input: {
                          borderColor: "#6E23CF",
                          borderWidth: "3px",
                        },
                      }}
                      placeholder="First name"
                    />
                    <Input
                      value={human.lastName}
                      onChange={(e) =>
                        onChangeHuman("lastName", e.target.value, human.id)
                      }
                      radius={"md"}
                      mt={5}
                      size="lg"
                      styles={{
                        input: {
                          borderColor: "#6E23CF",
                          borderWidth: "3px",
                        },
                      }}
                      placeholder="Last name"
                    />
                    <DateInput
                      size="lg"
                      mt={5}
                      radius={"md"}
                      styles={{
                        input: {
                          borderColor: "#6E23CF",
                          borderWidth: "3px",
                        },
                      }}
                      value={human.dob}
                      onChange={(value) =>
                        onChangeHuman("dob", value, human.id)
                      }
                      placeholder="mm/dd/yyyy"
                    />
                  </Flex>
                ))}

                <ActionIcon
                  style={{ borderWidth: 3 }}
                  radius={"md"}
                  h={50}
                  w={50}
                  mt={3}
                  variant="outline"
                  onClick={addHuman}>
                  <IconPlus />
                </ActionIcon>
              </Flex>
            </Fragment>
          );
        }
      }
    } else {
      return null;
    }
  };

  return (
    <Card
      className="custom-scrollbar responsive-card"
      style={{ overflowY: "auto" }}
      radius={"md"}
      mih={"55vh"}
      display={"flex"}
      p={15}>
      <Flex direction={"column"} justify={"space-between"} h={"100%"} gap={20}>
        <Flex direction={"column"}>
          <Text fw={500}>Hey {data.name}</Text>
          <Space h="lg" />
          <Text>
            It looks like your doctor prescribed you a custom-made compounded
            medication. We made it super easy to order using the form here. If
            you have any immediate questions, call us at (215) 238-9055.
          </Text>
          <Space h="lg" />
          <div>
            <Text>Please enter your email.</Text>
            <Input
              size="lg"
              type="email"
              value={data.email}
              onChange={(e) =>
                setData({
                  ...data,
                  email: e.target.value,
                })
              }
              mt={5}
              maw={"300px"}
              radius={"md"}
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "3px",
                },
              }}
              placeholder="Enter email"
              error={
                data.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)
                  ? "Invalid email address."
                  : null
              }
            />
          </div>
          {isNewMember(expectingNewMember, newMemberType)}
        </Flex>

        <Flex direction={"column"} gap={5}>
          <Checkbox
            checked={data.termsAgree}
            onChange={(e) =>
              setData({
                ...data,
                termsAgree: e.target.checked,
              })
            }
            label={
              <Text>
                I agree to{" "}
                <a href="/privacy-policy" target="__blank">
                  terms of service/privacy policy
                </a>
                .
              </Text>
            }
            error={
              data?.termsAgree
                ? ""
                : "Please agree to the terms of service/privacy policy."
            }
          />
        </Flex>
      </Flex>
    </Card>
  );
}

export default AddPatient;
